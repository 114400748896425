import React, { useEffect, useRef, useState } from 'react'
import { Affix, Checkbox, Typography } from 'antd'
import BasicActionButtons from '@components/basic-action-buttons'
import { authActions } from '@store/actions/auth'
import { QUESTION_SET_FIELDS, USER_FIELDS } from '@constants/db'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CSS_VARIABLES, REDUX_STATE, ROUTE_PATH } from '@src/constants'
import { getCSSVariable, isMobile } from '@src/utils'
import { HoverCardWrapper } from '@src/styles/wrapper'
import { recordActions } from '@store/actions/record'
import { questionSetActions } from '@store/actions/question-set'
import SecurePDFViewer from '@components/secure-pdf-viewer'
import ConsentPDF from '@src/static/5. Consent_ACT study 9-9-24.pdf'

const ConsentFormPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.USER]
  )
  const questionSet = useSelector(
    (state) =>
      state?.[REDUX_STATE.QUESTION_SET.NAME]?.[REDUX_STATE.QUESTION_SET.FIELDS.QUESTION_SET]
  )
  const isUpdatingProfile = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.IS_UPDATING]
  )
  const error = useSelector(
    (state) => state?.[REDUX_STATE.AUTH.NAME]?.[REDUX_STATE.AUTH.FIELDS.ERROR]
  )
  const [checked, setChecked] = useState(false)
  const prevIsUpdating = useRef(isUpdatingProfile)
  const userId = user?.[USER_FIELDS.ID.NAME]
  const questionSetId = questionSet?.[QUESTION_SET_FIELDS.ID.NAME]

  /**
   * Get question set
   */
  useEffect(() => {
    dispatch(questionSetActions.getQuestionSet())
  }, [dispatch])

  /**
   * Go to session page after confirm
   */
  useEffect(() => {
    if (!error && !isUpdatingProfile && prevIsUpdating.current) {
      navigate(ROUTE_PATH.SESSIONS.NAME)
    }
    prevIsUpdating.current = isUpdatingProfile
  }, [navigate, error, isUpdatingProfile])

  const onConfirm = () => {
    if (questionSetId) {
      dispatch(
        authActions.updateProfile({
          id: userId,
          body: { [USER_FIELDS.READ_CONSENT_FORM.NAME]: true }
        })
      )
      dispatch(recordActions.initRecord({ questionSetId }))
    }
  }

  return (
    <div>
      <HoverCardWrapper
        style={{
          margin: '0 auto',
          width: isMobile ? '100%' : '500px'
        }}
      >
        <Affix offsetTop={parseInt(getCSSVariable(CSS_VARIABLES.HEADER_HEIGHT).replace('px', ''))}>
          <div
            style={{
              marginBottom: '20px',
              height: '90px',
              backgroundColor: 'white'
            }}
          >
            <BasicActionButtons
              okText={'Confirm'}
              style={{ marginBottom: '10px' }}
              cancelText={'Delete account'}
              cancelIsDanger={true}
              onCancel={() => dispatch(authActions.deleteAccount(userId))}
              onOk={onConfirm}
              okDisabled={!checked}
            />
            <Typography.Title level={4}>Consent Form</Typography.Title>
          </div>
        </Affix>
        <div style={{ overflowY: 'scroll', height: `calc(100% - 100px)` }}>
          <Typography.Paragraph>
            Please read through the following document to confirm that you consent to participate in
            this research project.
          </Typography.Paragraph>
          <SecurePDFViewer file={ConsentPDF} />
          <Typography.Paragraph>
            By clicking the box below you certify that you agree to all of the information in the
            consent form, and you understand that ticking this box is the equivalent to signing a
            physical document.
          </Typography.Paragraph>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
            <Checkbox onChange={(e) => setChecked(e.target.checked)} /> I agree
          </div>
        </div>
      </HoverCardWrapper>
    </div>
  )
}

export default ConsentFormPage
